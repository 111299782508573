*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-size: calc(8px + 1vmin);
  color: white;
  background:  linear-gradient(30deg, #000000 20%, #1B1B1B 70%);
}
.App { 
  width: 100%;
  height: 100vh;
}
.nav{
  height: 10vh;
  width: auto;
  background-color: #101010;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
a{
  text-decoration: none;
  /* color: white; */
  padding: 8px;
  cursor: pointer;
  color: white;
}
a:active{
  color: rgb(108, 108, 244);
}
.container{
  width: auto;
}
.container-title{
  width: auto;  
  text-align: center;
}
.container-title > h3{
  font-size: 2rem;
  letter-spacing: 0.18rem;
}
.container-form{
  width: auto;
  height: 80vh;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.container-form  > button {
  cursor: pointer;
  background-color: darkgray;
  width: 22vh;
  height: 5vh;
  border: 1px solid white;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 40px;
  border-radius:  65px ;
}
button:active{
  background-color: blue;
}
.footer{
  width: auto;
  height: 4vh;
  text-align: center;
  align-items: center;
}
.footer > p{
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 2.5px;
}